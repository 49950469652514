<template>

  <div>

    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card no-body>
        <b-card-body>
            <b-row>
                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                
                    <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sites"
                    label="site_name"
                    class="w-100"
                    placeholder="Project Site"
                    v-model="siteData"
                    @input="filterTable"
                    :clearable="false"
                  >
                    <template #selected-option="{ site_name }">
                      <span v-if="site_name.length < 23">{{site_name}}</span>
                      <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                    </template>
                  </v-select>
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                  <b-form-group>
                    <v-select 
                      v-model="filter_type" 
                      @input="filterTable" 
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                      label="name" 
                      :options="filter_types" 
                      placeholder="Select Filter Type"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" class="mb-md-0 mb-2">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="months"
                    class="w-100"
                    placeholder="Month"
                    @input="getDate(); filterTable();"
                    v-model="month"
                    :clearable="false"
                  />
                </b-col>

                <b-col cols="12" md="2" class="mb-md-0 mb-2">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="yearData"
                    class="w-100"
                    placeholder="Year"
                    @input="getDate(); filterTable();"
                    v-model="year"
                    :clearable="false"
                  />
                </b-col>

                

                <b-col cols="12" md="2" class="mb-md-0 mb-2">
                    <!-- v-if="checkPermission($route.name, 'Pdf')" -->
                  <b-button
                    :disabled="siteData && siteData._id != 'all-site' ? false:true"
                    variant="success"
                    class="mobile-margin-bottom float-right"
                    @click="downloadPdf()"
                  >
                    <span class="text-nowrap">PDF</span>
                  </b-button>
                </b-col>

            </b-row>
        </b-card-body>
    </b-card>

    <b-row class="match-height">
      
      <b-col md="4">
        <statistic-card-horizontal
          color="success"
          statistic="Happy"
          :count="happy"
        />

      </b-col>

      <b-col md="4">
        <statistic-card-horizontal
          color="danger"
          statistic="Unhappy"
          :count="unhappy"
        />

      </b-col>
      
    </b-row>

    <b-card no-body class="mb-0">

      <b-table sticky-header
          responsive
          show-empty
          ref = "refUserListTable"
          class = "position-relative"
          primary-key = "id"
          empty-text = "No matching records found"
          :fields = "tableColumns"
          :items = "dataList"
          
      >
          <template #cell(site)="items">
              <p class="wordBreak">{{ items.item.site.site_name | capitalize}}</p>
          </template>

          <template #cell(name)="items">
              <p class="wordBreak">{{ items.item.name | capitalize}}</p>
          </template>                
          
          <template #cell(happy)="items">
              <p class="wordBreak">{{ items.item.happy }}</p>
          </template>

          <template #cell(unhappy)="items">
              <p class="wordBreak">{{ items.item.unhappy }}</p>
          </template>

          

      </b-table>

    </b-card>

    

      
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BForm,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,BFormGroup, BFormCheckbox,BCardBody,BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    StatisticCardVertical,
    StatisticCardHorizontal,
    BBreadcrumb
  },
  data() {
    return {
        webUrl: process.env.VUE_APP_SERVER_URL,
        showDismissibleAlert:false,

        tableColumns: [
            
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '25%'}},
            { key: 'name', label: 'Location Name', sortable: false , thStyle:  {width: '25%'}},
            { key: 'happy', label: 'Happy', sortable: false , thStyle:  {width: '25%'}},
            { key: 'unhappy', label: 'Unhappy', sortable: false , thStyle:  {width: '25%'}},
        ],

        happy:0,
        unhappy:0,
        siteData:null,
        sites:[],
        items: [],

        filter_types: ['QR', 'Feedback Panel', 'Both'],
				filter_type: 'Both',

        // date vars
				yearData:[],
				year:moment().format('YYYY'),
				months:['January', 'February', 'March', 'April', 'May', 'June', 'July ', 'August', 'September', 'October', 'November', 'December'],
				month:moment().format('MMMM'),
				start: null,
				end: null,

    }
  },
  methods : {    
    allSites(){
        return this.$store.dispatch(POST_API, {
            data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            },
            api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                if (this.sites.length == 1 ) {
                    this.siteData = this.sites[0];
                    this.filterTable();
                }

                /* var obj = {
                    _id:'all-site',
                    site_name:'All Project Sites'
                }
                this.sites.unshift(obj); */
                
                return this.sites;
            }
        });
    },
    dataList(){
        return this.$store.dispatch(POST_API, {
            data:{
                role          : this.$store.getters.currentUser.role,
                om_sites      : this.$store.getters.currentUser.om_sites,
                site          : this.siteData ? this.siteData._id :null,
                start         : this.start,
							  end           : this.end,
                filter_type   : this.filter_type,
            },
            api: '/api/qr-overview'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.items          = this.$store.getters.getResults.data.items;
                this.happy          = this.$store.getters.getResults.data.happy;
                this.unhappy        = this.$store.getters.getResults.data.unhappy;
                return this.items;
            }
        });
    },

    filterTable(){
        this.$refs.refUserListTable.refresh();
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'QR Feedback',
      },{
        to:null,
        text:'Overview',
        active:true
      }];
      return item;
    }, 

    getDate(){
      var date = null;
      if (this.month == null && this.year == null) {
        this.start = null;
        this.end = null;
      }else if(this.month == null && this.year != null){
        date = moment.utc().startOf('month').format('MM-DD');
        var year = this.year;
        date =  year.toString() + '-'+ date;
        date = moment.utc(date).startOf('year').format('YYYY-MM-DD');
        this.start = date;
        this.end = moment(date).endOf('year').format('YYYY-MM-DD');
      }else if(this.month != null && this.year == null){
        var year = moment.utc().startOf('month').format('YYYY');
        date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
        date = moment.utc(date).startOf('month').format('YYYY-MM-DD');
        this.start = date;
        this.end = moment(date).endOf('month').format('YYYY-MM-DD');
      }else{
        var year = this.year.toString();
        date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
        date = moment.utc(date).startOf('month').format('YYYY-MM-DD');
        this.start = date;
        this.end = moment(date).endOf('month').format('YYYY-MM-DD');
      }
    },

    calYear(){
      var year = moment().format('YYYY');
      var yearData = [];
      var start = parseInt(year) - 2;
      var end =  parseInt(year) + 5;

      for (var i = start ; i <= end; i++) {
        yearData.push(i);
      }

      this.yearData = yearData;
    },

    downloadPdf(){
      var organization = this.$store.getters.currentUser.organization;
      var site         = this.siteData ? this.siteData._id : "";
      var role         = this.$store.getters.currentUser.role;
      var om_sites     = this.$store.getters.currentUser.om_sites;
      var start        = this.start;
      var end          = this.end;
      var filter_type  = this.filter_type;

      var params = "organization="+organization+"&site="+site+"&role="+role+"&om_sites="+om_sites+"&start="+start+"&end="+end+"&filter_type="+filter_type;
      
      var baseurl = "";
    
      baseurl = process.env.VUE_APP_SERVER_URL +"/api/qr-overview-pdf?"+params;

      window.open(baseurl,'_blank');
    },
  },
  mounted(){
    this.allSites();
    this.calYear();
    this.getDate();    
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
